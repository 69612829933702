import React from 'react';

// Imagens
import orientacoes1 from '../../img/Orientações de participação 1.jpg';
import orientacoes2 from '../../img/Orientações de participação 2.jpg';
import orientacoes3 from '../../img/Orientações de participação 3.jpg';

// Css
import './index.css';

const Orientacoes = () => {
  return (
    <>
      <div className="imagesOrientations" style={{ width: '50%', margin: '0 auto' }}>
        <img className="img-fluid" src={orientacoes1} />
        <img className="img-fluid" src={orientacoes2} />
        <img className="img-fluid" src={orientacoes3} />
      </div>
    </>
  );
};

export default Orientacoes;
