import React from 'react';

import './index.css';

import { Link } from 'react-router-dom';

import logo from '../../img/logo_rainha.png';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const NavbarAll = () => {
  return (
    <>
      <Navbar className="container-navbar" expand="lg">
        <Container>
          <Navbar.Brand>
            <img src={logo} className="logo-navbar" alt="logo rainha da antiga religião" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="config-navbar">
              <Nav.Link>
                <Link className="links-navbar" to="/">
                  Inicio
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link className="links-navbar" to="/quem-somos">
                  Quem Somos
                </Link>
              </Nav.Link>
              <NavDropdown className="dropdown-title" id="basic-nav-dropdown" title="Artigos">
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/importancia-da-dieta">
                    Importância da Dieta
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/orientacoes-de-participacao">
                    Orientações de Participação
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link className="links-navbar" to="/linktree">
                  Cursos
                </Link>
              </Nav.Link>
              <NavDropdown
                className="dropdown-title"
                id="basic-nav-dropdown"
                title="Cursos"
                style={{ display: 'none' }}
              >
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/cursos-em-terapias">
                    Cursos em Terapias
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/cursos-em-reiki">
                    Cursos em Reiki
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/cursos-em-energias">
                    Cursos em Energias
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/cursos-oraculares">
                    Cursos Oraculares
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/cursos-em-magias">
                    Cursos em Magias
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/workshops">
                    WorkShops
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="links-navbar" to="/cursos/oficinas">
                    Oficinas
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarAll;
